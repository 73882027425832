import { CaretRightOutlined } from "@ant-design/icons"
import { Skeleton } from "antd";

const InvestmentsSidebar = ({
    selectedOption,
    handleInvestmentOption,
    translations,
    sidebarOptions,
    modulesPermitted,
    loading
}: any) => {

    return (
    <div className="investments-sidebar">
        {modulesPermitted.includes('Plan Your Investment/Plan Your Investment')
            ? <div className="investments-sidebar-plan">
                <h3>{translations.planifica}</h3>
                {sidebarOptions.plan.map((option: any) => {
                    return (
                        <div className="investment-item">
                            <button
                                className={selectedOption === option.id ? "selectedOption" : ""}
                                onClick={() => handleInvestmentOption(option.id)}
                            >
                                <p>{translations[option.text]}</p>
                                <CaretRightOutlined />
                            </button>
                        </div>
                    )
                })}
            </div>
            : null
        }
        {modulesPermitted.includes('Analyze Your Investment/Analyze Your Investment') 
            ? <div className="investments-sidebar-analyze">
                <h3>{translations.analiza}</h3>
                {sidebarOptions.analyze.map((option: any) => {
                    return (
                        <div className="investment-item">
                            <button
                                className={selectedOption === option.id ? "selectedOption" : ""}
                                onClick={() => handleInvestmentOption(option.id)}
                            >
                                <p>{translations[option.text]}</p>
                                <CaretRightOutlined />
                            </button>
                        </div>
                    )
                })}
            </div>
            : null
        }
        {(loading && modulesPermitted.length === 0) && 
            <div className="skeleton-container">
                <Skeleton active style={{ width: "210px" }} />
            </div>
        }
    </div>
)};
  
export default InvestmentsSidebar