import axios from "axios";
import { notifyError } from "../utils/Fn";
import { notification } from "antd";

let apiConfig = {
	getAuthToken: () => '',
	userId: ''
};

const initAPIs = (getAuthToken: () => string, userId: any) => {
	apiConfig.getAuthToken = getAuthToken;
	apiConfig.userId = userId;
};

const AxiosAPI = axios.create({
	baseURL: process.env.REACT_APP_WebAPI_BaseURL,
	headers: {
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	}
});

const AxiosWebAPI = axios.create({
    baseURL: process.env.REACT_APP_WebApiURL,
    headers: {
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
});

const AxiosServiceAPI = axios.create({
	baseURL: process.env.REACT_APP_ServiceUrl,
	headers: {
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	}
});

AxiosWebAPI.interceptors.request.use(function (config) {
    if(config?.headers){
        config.headers['Authorization'] = `Bearer ${apiConfig.getAuthToken()}`;
    }
    return config;
});

AxiosServiceAPI.interceptors.request.use(function (config) {
	if(config?.headers){
		config.headers['Authorization'] = `Bearer ${apiConfig.getAuthToken()}`;
	}
  	return config;
});


AxiosWebAPI.interceptors.response.use(undefined, async function (error) {
    if (error.response.status === 401) {
      	window.location.href = '/login';
    }
	if(error.response.status === 500){
		notifyError(
			error.config?.baseURL, 
			error.config?.url,
			apiConfig.userId, 
			error.config?.data,
			apiConfig.getAuthToken()
		);
		window.location.href = '/UnderMaintenance';
	}
});

AxiosServiceAPI.interceptors.response.use(undefined, async function (error) {
  	if (error.response.status === 401) {
    	window.location.href = '/login';
  	}
	  if(error.response.status === 500){
		notifyError(
			error.config?.baseURL, 
			error.config?.url,
			apiConfig.userId, 
			error.config?.data,
			apiConfig.getAuthToken()
		);
		window.location.href = '/UnderMaintenance';
	}
});

AxiosAPI.interceptors.request.use((config) => {
	const newConfig = { ...config };
	if (newConfig?.headers) {
		newConfig.headers.Authorization = `Bearer ${apiConfig.getAuthToken()}`;
	}
	return newConfig;
});

AxiosAPI.interceptors.response.use(undefined, (error) => {
	if (!error.response?.data?.Success && error.response?.data?.ErrorMessage) {
		const url = `${error.response.status}, ${error.config.url}.`;
		const err = `${error.response.data.ErrorMessage ?? 'An error ocurred.'}`;
		notification.error({ message: err, description: url });
		console.log(err);
	} else if (error.response.status !== 200 || error.response.status !== 204) {
		notification.error({ message: `Error: ${error.message}` });
		console.log(error);
	}
	if (error.response.status === 401) {
		window.location.href = '/login';
	}
	if (error.response.status === 500) {
		window.location.href = '/UnderMaintenance';
	}
	return error;
});


export { AxiosWebAPI, AxiosServiceAPI, initAPIs, AxiosAPI };